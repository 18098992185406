<template>
  <b-container style="width:100% !important; max-width: 100% !important;">
    <div v-if="this.contractinfo">
      <a v-if="current_user.role == 'admin'" href="javascript:;" class="action-item float-left" @click="prevweek()"
        :title="$t('buttons.previousweek')">
        <font-awesome-icon size="2x" icon="arrow-circle-left" />
      </a>
      <a v-if="current_user.role == 'admin'" href="javascript:;" class="action-item" @click="nextweek()"
        :title="$t('buttons.nextweek')">
        <font-awesome-icon size="2x" icon="arrow-circle-right" />
      </a>
      <b-form-checkbox v-model="show_weekend" name="checkbox-1" value="1" unchecked-value="0">
          {{ $t('labels.showweekend') }}</b-form-checkbox>
      <div class="d-flex justify-content-center"><strong><h1>Week {{ dayjsweekno }}
          {{dayjsyearno}}</h1></strong></div>
          <!-- {{ facturen.filter(invoice => invoice.contract_id==this.contract_id) }} {{ this.contract_id }} <button @click="addfactuur">sss</button> -->
      <div v-if="week_exported"><h4 style="color:red">{{$t('validation.invoicealreadyexported')}}</h4> </div>
      <table class="table">
        <thead>
          <tr>
            <th > {{$t('labels.daymonday')}}</th>
            <th > {{$t('labels.daytuesday')}}</th>
            <th > {{$t('labels.daywednesday')}}</th>
            <th > {{$t('labels.daythursday')}}</th>
            <th > {{$t('labels.dayfriday')}}</th>
            <th v-if="show_weekend==1"> <i>{{$t('labels.daysaturday')}}</i></th>
            <th v-if="show_weekend==1 "> <i>{{$t('labels.daysunday')}}</i></th>
            <th > {{$t('labels.actions')}}</th>

          </tr>
        </thead>
        <!-- {{ this.contractinfo[0].dates }} -->
        <tr v-for="(item, index) in this.contractinfo[0].dates" :key="index">
          <template>
            <td v-for="(tditem, tdindex) in item" :key="tdindex" :style="tdclass(tdindex)">
              <div v-if="tditem!='0' && (tdindex<5  ||show_weekend ==1)">
                <div class="workday">
                  <font-awesome-icon v-if="issigned(tditem)==false" icon="times" style="color: red;" />
                  <font-awesome-icon v-else icon="signature" style="color: green;" />
                  {{dateToNL(tditem)}}
                </div>
                <div style="color: red;"  v-if="feestdag(dateToNL(tditem))!=''">{{ feestdag(dateToNL(tditem)) }}</div>
                <div class="workdata" v-if="hasdata(tditem)==true">
                  <div v-for="(ahourdata, index) in hourdata(tditem)" :key="'somehours'+index">
                    <FilledHours v-if="ahourdata.hours!=0 ||ahourdata.declaraties" :hourdata=ahourdata></FilledHours>
                  </div>
                </div>
                <div v-if="dateActive(tditem)==true" class="workedit">
                  <a v-if="current_user.role=='admin'" href='javascript:;' class='action-item'
                    @click="fillhours(tditem)">
                    <font-awesome-icon v-if="week_exported" color="red" icon="pencil" />
                    <font-awesome-icon v-else  icon="pencil" />
                  </a> 
                  <a v-if="current_user.role=='admin' && settings.RegisterWorkhours &&  hassignature(tditem, user_id, contract_id)" href='javascript:;' class='action-item'
                    @click="showwerkbriefje(tditem, user_id, contract_id)"> <font-awesome-icon icon="eye" />{{ $t('buttons.signed') }}</a>
                    <a v-if="current_user.role=='admin' && contract_by_id(contract_id).workhours !='yes' &&  hasonlysignature(tditem, user_id, contract_id)" href='javascript:;' class='action-item'
                    @click="showsignature(tditem, user_id, contract_id)"> <font-awesome-icon icon="signature" />    {{signatures.filter(s=>s.user_id==user_id&&s.workdate==tditem&& s.contract_id==contract_id)[0].signed_by}}
</a>                    
                </div>
              </div>
            </td>
          </template>
          <td v-if="week_is_closed()">
            <table v-if="contract_by_id(contract_id).invoiceperhourcode!='yes'">
              <tr v-if="hasinvoices"><td><div class="btn btn-primary" @click="generated_invoices()"><b-spinner :style="AppPrimaryColor()" small v-if="generating"></b-spinner>{{$t('buttons.regenerateinvoices')}}</div></td></tr>
              <tr v-if="hasinvoices"><td>
                <a v-if="settings.ShowInvoicePurchaseButton==true && current_user.role=='admin' && inkoopfactuur=='yes'" href='javascript:;' class='btn btn-primary float-left'
                  @click=" generate_factuur(item)" :title="$t('labels.invoice_inkoop')">
                  <font-awesome-icon v-if="!generate_inkoop" size="2x" :icon="['far','file-invoice']" />
                  <b-spinner small v-else></b-spinner>{{ $t('labels.invoice_inkoop') }}
                </a>

              </td></tr>
              <tr v-if="hasinvoices"><td>
                <a v-if="settings.ShowInvoiceSaleButton==true && current_user.role=='admin'" href='javascript:;' class='btn btn-primary float-left'
                  @click="generate_verkoop_factuur(item)" :title="$t('labels.invoice_verkoop')">
                  <font-awesome-icon v-if="!generate_verkoop" size="2x" icon="file-invoice" />
                  <b-spinner small v-else></b-spinner>{{ $t('labels.invoice_verkoop') }}
                </a>
              </td></tr>
              <tr><td>
                <div v-if="current_user.role!='user' && hasunsigned(index) && hoursToSign.length==0" class="btn btn-secondary"
                  @click="sign_week(item)">
                  <font-awesome-icon icon="signature" v-if="generating==false" /> 
                  <b-spinner :style="AppPrimaryColor()" small v-else></b-spinner> 
                  {{ $t("buttons.hoursvalidated") }}
                </div>         
                <div v-else >
                  <font-awesome-icon icon="comment"/>
                  <p v-html="hourSignData" />
                  </div>
              </td></tr>
            </table>
            <table v-else>
              <tr><td><strong><font-awesome-icon icon="check" color="green" /> {{ $t('labels.invoicebyhourcode') }}</strong></td></tr>
              <tr v-if="hasinvoices"><td><div class="btn btn-primary" @click="generated_invoices()"><b-spinner :style="AppPrimaryColor()" small v-if="generating"></b-spinner>{{$t('buttons.regenerateinvoices')}}</div></td></tr>
              <tr v-if="hasinvoices"><td>
                <b-dropdown id="dropdown-dropup" dropup :text="$t('labels.invoice_inkoop')" variant="primary">
                  <b-dropdown-item v-for="(code) in allhourcodes" :key="'inkoop'+code" @click="generate_factuur(item, parseInt(code))">
                    <font-awesome-icon icon="file-invoice" /> {{ hourcode_by_id(parseInt(code)).name }}
                  </b-dropdown-item>
                </b-dropdown>
              </td></tr>
              <tr v-if="hasinvoices"><td>
                <b-dropdown id="dropdown-dropup" dropup :text="$t('labels.invoice_verkoop')" variant="primary">
                  <b-dropdown-item v-for="(code) in allhourcodes" :key="'inkoop'+code" @click="generate_verkoop_factuur(item, parseInt(code))">
                    <font-awesome-icon icon="file-invoice" /> {{ hourcode_by_id(parseInt(code)).name }}
                  </b-dropdown-item>
                </b-dropdown>                
              </td></tr>
              <tr><td>
                <div v-if="current_user.role!='user' && hasunsigned(index) && hoursToSign.length==0" class="btn btn-secondary"
                  @click="sign_week(item)">
                  <font-awesome-icon icon="signature" /> {{ $t("buttons.hoursvalidated") }}
                </div>         
                <div v-else >
                  <font-awesome-icon icon="comment"/>
                  <p v-html="hourSignData" />
                  </div>
              </td></tr>
            </table>            
          </td>
          <td v-else>
            <div>{{$t('labels.weeknotclosed')}}</div>
            <b-button variant="primary" v-if="!week_is_closed()" @click="close_week()">{{ $t("buttons.closeweek") }}
            </b-button>
          </td>
        </tr>

      </table>
      <hr>
      <div class="row">
        <div class="col">
          <div class="week-overview" v-html='weektotal()'/>
          <div v-if="current_user.id==1" v-html="fake_sign()" />
        </div>
        <div class="col">
          <div v-if="current_user.role=='admin'" href='javascript:;' class="btn btn-primary" @click="upload_urenbon(false)"
            :title="$t('buttons.addurenbon')">
            <font-awesome-icon icon="upload" />{{$t("buttons.addurenbon")}}
          </div>
          <div v-if="false && current_user.role=='admin'" href='javascript:;' class="btn btn-primary" @click="upload_urenbon(true)"
            :title="$t('buttons.addurenbon')">
            <font-awesome-icon icon="file-invoice" />{{$t("buttons.invoicezzper")}}
          </div>          
          <UrenBonnen :contract_id="contract_id" :week_number="dayjsweekno" :year_number="yearnumber"
            :isclosed="week_is_closed()" />            
        </div>
        <div class="col">
          <div v-if="invoiceextraweek.length>0">
            <div v-if="invoiceextraweek[0].absent==1">
              <strong>{{$t("labels.setabsent")}} </strong>
              {{ invoiceextraweek[0].reason }}
            </div>

            {{ $t("labels.invoice_extra_description") }} <strong>{{invoiceextraweek[0].description}}</strong> <br/>
            {{ $t("labels.invoice_extra_various") }} <strong>{{invoiceextraweek[0].extra}}</strong>

          </div>
          <div v-if="current_user.role=='admin'" href='javascript:;' class="btn btn-primary" @click="invoice_extra_info()"
            :title="$t('buttons.invoiceextrainfo')">
            <font-awesome-icon icon="info" />{{$t("buttons.invoiceextrainfo")}}
          </div>
          <div v-if="!isabsent" class="btn btn-primary" @click="showAbsent">{{$t('labels.setabsent')}}</div>
          <div v-else class="btn btn-primary" @click="setPresent">{{$t('labels.setpresent')}}</div>
          {{ absentstate }} {{ absent?"a":"b" }} tralala
        </div>
      </div>
      <BSUploadUserModal :contract_id="contract_id" :weeknumber="dayjsweekno" :yearnumber="yearnumber" :internal="uploadintern" :companyattachment="companyattach" />
      <BSInvoiceExtra :contract_id="contract_id" :weeknumber="dayjsweekno" :yearnumber="yearnumber" />
      <BSSignHoursModal :contract_id="contract_id" :weeknumber="dayjsweekno" :yearnumber="yearnumber" @prehide="hideSignHoursModal"/>
      <BSAbsentModal :contract_id="contract_id" :weeknumber="dayjsweekno" :yearnumber="yearnumber" @prehide="hideAbsent"/>

    </div>
    <div v-else>
      Not signed ZZPer
    </div>
    <!-- <UserHoursModal :day="String(showday)" :contract_id="contract_id" /> -->
    <UserHoursModal  />

  </b-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
// import add_user_hours_modal from '../modals/UserHoursModal'
import axios from 'axios'
import FilledHours from '../FilledHours.vue'
import UrenBonnen from './ActiveUrenbonnen.vue'
import popup_modal from '../modals/PopupModal.vue'
import UserHoursModal from '../modals/UserHoursModal'
import BSUploadUserModal from '../modals/BSUploadUrenModal.vue'
import BSInvoiceExtra from '../modals/BSInvoiceExtra.vue'
import BSSignHoursModal from '../modals/BSSignHoursModal.vue'
import BSAbsentModal from '../modals/BSAbsentModal.vue'
import dayjs from 'dayjs'

//const querystring = require("querystring");

export default {
  name: 'schedule_screen',
  data() {
    return {
      agreemode: false,
      weeknumber: 47,
      yearnumber: 2022,
      dayofweek: -1,
      showday: 0,
      uploadintern:false,
      generate_inkoop:false,
      generate_verkoop:false,
      generating:false,
      show_weekend:0,
      hoursToSign:[],
      week_year:"",
      getsigndata:null,
      absentstate:0

    }
  },
  props: {
    user_id: Number,
    contract_id: Number,
    week_number: Number
  },
  async mounted() {
    //open previous week
    // let now = new Date(new Date().getTime() - + 7 * 24 * 60 * 60 * 1000)
    // console.log("schedule mounted ", this.week_number)
    let now = new Date()
    if(now>this.enddate){
      now = this.enddate
    }
    console.log(">NOW",now, this.startdate, this.week_number)

    console.log(">mount",this.getWeekNumber(now), now)
    this.week_year = this.getWeekNumber(now)
    let start = dayjs(now)
    if(this.week_number!=-1){
      // let wd = this.getWeekOfYear(this.dayjsyearno, this.week_number)[6]
      let wd = this.getWeekOfYear(start.year(), this.week_number)[6]
      // console.log("WD", wd, this.contractinfo[0].dates[0],this.dayjsyearno)
      start = dayjs(new Date(wd))
    }
    console.log("START ", start.year())

    this.weeknumber=start.diff(this.startdate, 'week')
    if(this.weeknumber<0)
        this.weeknumber=0

    console.log("schedule end mounted ", this.weeknumber,start)

    // }
    this.yearnumber = this.week_year[0]

    await  axios.get("/api/get_contract_sign/" + this.contract_id)    .then((response) => {
        this.getsigndata = response.data
    })

          let abs= this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.dayjsweekno && i.year == this.dayjsyearno)
      this.absentstate= abs.length>0 && abs[0].reason!=""?0:1

  },
  computed: {
    dayjsweekno(){
      let i=0;
      while(parseInt(this.contractinfo[0].dates[0][i])==0){
        i++
      }
      return dayjs(this.contractinfo[0].dates[0][i]).isoWeek()
    },
    dayjsyearno(){
      let i=6;
      while(parseInt(this.contractinfo[0].dates[0][i])==0){
        i--
      }
      return dayjs(this.contractinfo[0].dates[0][i]).year()
    },
    companyattach(){
      // console.log("ca",this.company_by_id(this.project_by_id(this.contract_by_id(this.contract_id).project_id).company_id))
      return this.company_by_id(this.project_by_id(this.contract_by_id(this.contract_id).project_id).company_id).needsattachment=="yes"

    },
    isabsent(){
      let abs= this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.dayjsweekno && i.year == this.dayjsyearno)
      // this.absentstate=?abs.length>0 && abs[0].reason!=""?1:0
      return abs.length>0 && abs[0].reason!=""

    },
    allhourcodes(){
      let lochourcodes =[]
      // console.log("allhourcodes",this.contractinfo[0].dates)
      this.contractinfo[0].dates[0].forEach(date =>{
        let data= this.hourdata(date)
        if(data){
          if(data[0].hourcode && lochourcodes.indexOf(data[0].hourcode)==-1){
            lochourcodes.push(data[0].hourcode)
          }
            
          
        }
      })
      
      return lochourcodes

    },
    hourSignData(){
      if(this.getsigndata){
        let signdata = this.getsigndata.filter(h => h.workdate == "sign"+this.contract_id+"-"+this.dayjsweekno+"-"+this.yearnumber)
      
      // let signdata = this.hours.filter(h => h.workdate == "sign"+this.contract_id+"-"+this.dayjsweekno+"-"+this.yearnumber)
      if(signdata && signdata[0]){
        return signdata[0].added_date+"<br>"+signdata[0].comment
      } else{
        return ""
      }
    }
    return ""
    },
    hasinvoices(){
      return this.facturen.filter(invoice => invoice.contract_id==this.contract_id && invoice.week == this.dayjsweekno).length>0
    },

    inkoopfactuur() {
      // console.log("fetch inkoop",this.contract_id)
      let contract = this.contract_by_id(this.contract_id)
      let inkoop = this.userplanning(contract.user_id).filter(plan =>{
        return plan.project_id == contract.project_id
      })
      // console.log(inkoop)
      return inkoop[0].inkoopfactuur?inkoop[0].inkoopfactuur:"yes"
    },
    startdate() {
      let contract = this.contract_by_id(this.contract_id)
      let userplan = this.userplanning(contract.user_id)
      for (const plan of userplan) {
        if (plan.project_id == contract.project_id) {
          return new Date(plan.startdate)
        }
      }
      return null
    },
    enddate() {
      let contract = this.contract_by_id(this.contract_id)
      let userplan = this.userplanning(contract.user_id)
      for (const plan of userplan) {
        if (plan.project_id == contract.project_id) {      
          return new Date(plan.enddate)
        }
      }
      return null
    },
    contractinfo() {
      let values = [];
      var contract = this.contract_by_id(this.contract_id)
      var userplan = this.userplanning(contract.user_id)
      console.log("REDO contractinfo")
      for (var p = 0; p < userplan.length; p++) {
        let row = {};
        // signed by company not neccesary
        // if(contract.signed_zzpr==1 && contract.signed_company==1 &&userplan[p].project_id==contract.project_id){
        // if(contract.signed_zzpr==1 &&userplan[p].project_id==contract.project_id){
        if (userplan[p].project_id == contract.project_id) {
          let project = this.project_by_id(contract.project_id)
          if (project) {
            row.project = userplan[p].project_name
            row.contract_id = contract.id
            row.user_id = contract.user_id
            row.startdate = userplan[p].startdate
            row.enddate = userplan[p].enddate
            row.dates = []
            let contractstart = new Date(row.startdate);
            let contractend = new Date(row.enddate);

            let alldates = []

            for(var i=1;i<8;i++){
              let loopday = dayjs(row.startdate).add(this.weeknumber,'week').isoWeekday(i).format('YYYY-MM-DD')
              // console.log(loopday, row.startdate, this.weeknumber)

              let loop = new Date(loopday)
              if (loop.setHours(0,0,0) < contractstart.setHours(0,0,0) || loop.setHours(0,0,0) > contractend.setHours(0,0,0)) {
                alldates.push("0")
              } else {
                alldates.push(loopday)
              }            
            }


            while (alldates.length > 0) {
              row.dates.push(alldates.splice(0, 7))
            }
            if (row.dates.length > 0) {
              while (row.dates[row.dates.length - 1].length < 7) {
                row.dates[row.dates.length - 1].push("0")
              }
            }
          }
          if (contract.user_id == this.user_id) {
            values.push(row)
          }
        }
      }
      return values
    },
    getdates() {
      let values = [];
      let contract = this.contract_by_id(this.contract_id)
      let userplan = this.userplanning(contract.user_id)
      for (var p = 0; p < userplan.length; p++) {
        // signed by company not neccesary
        // if(contract.signed_zzpr==1 && contract.signed_company==1 &&userplan[p].project_id==contract.project_id){
        if (contract.signed_zzpr == 1 && userplan[p].project_id == contract.project_id) {
          let project = this.project_by_id(contract.project_id)
          if (project) {
            let startdate = new Date(userplan[p].startdate);
            let enddate = new Date(userplan[p].enddate);
            let startmonday = this.getMonday(startdate);
            var loop = new Date(startmonday);
            while (loop <= enddate) {
              let thisday = this.dateToYYYYMMDD(loop)
              var newDate = loop.setDate(loop.getDate() + 1);
              loop = new Date(newDate);
              values.push(thisday)
            }
          }
        }
      }
      return values
    },
    invoiceextraweek() {
      return this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.dayjsweekno && i.year == this.dayjsyearno)

    },
    week_exported(){
      let weekfactuur = this.facturen.filter(f=>f.contract_id==this.contract_id &&f.week==this.dayjsweekno)
      if(weekfactuur.length>0 && (weekfactuur[0].exportedinkoop==1 || weekfactuur[0].exportedverkoop==1)){
          return true
      }
      return false
    },    
    ...mapState([
      'projects',
      'users',
      'companies',
      'contracts',
      'branches',
      'planning',
      'settings',
      'current_user',
      'hours',
      'hourcodes',
      'urenbonnen',
      'facturen',
      'invoiceextra',
      'signatures',

    ]),
    ...mapGetters([
      'project_by_id',
      'company_by_id',
      'contract_by_id',
      'zzpers',
      'user_by_id',
      'username_by_id',
      'hours_by_id',
      'hourcode_by_id',
      'declaratie_by_id',
      'createAny',
      'server_url',
      'confirm_modal'
    ]),

  },

  methods: {
    tdclass(index) {
      if (this.dayofweek != -1 && this.dayofweek != index) {
        return "display:none"
      }
    },

    showAbsent(){
      this.$bvModal.show('BSAbsentModalID')
    },
    setPresent() {
      try {
        this.absentstate = 0
        axios.post('/api/set_week_absent', {contract_id:this.contract_id, week:this.dayjsweekno, year:this.yearnumber, absent:0, reason:""}).then(async () => {
          this.$store.dispatch('get_invoiceextra')
        })
      } catch (err) {
        console.log(err)
      }
    },    
    dateActive(item) {
      var now = new Date();
      var itemDate = new Date(item);
      return now > itemDate
    },

    hasdata(item) {
      for (var ahour of this.hours) {
        if (ahour.contract_id == this.contract_id && ahour.workdate == item && ahour.user_id == this.user_id) {
          return true
        }
      }
      return false
    },

    hourdata(item) {
      var returnhours = [];
      for (var ahour of this.hours) {
        if (ahour.contract_id == this.contract_id && ahour.workdate == item && ahour.user_id == this.contract_by_id(this.contract_id).user_id) {
          let thishour = {}
          thishour.id = ahour.id
          thishour.comment = ahour.comment
          thishour.hours = ahour.hours
          thishour.free1 = ahour.free1
          thishour.free2 = ahour.free2          
          thishour.workdate = ahour.workdate
          thishour.agree_company = ahour.agree_company
          thishour.contract_id = ahour.contract_id
          thishour.close_hours = ahour.close_hours
          if (ahour.hourcode) {
            thishour.project = ahour.hourcode//this.hourcode_by_id(ahour.hourcode)?this.hourcode_by_id(ahour.hourcode).code:0
          }
          thishour.hourcode = ahour.hourcode
          //build declaraties
          let declaraties = []
          let contract = this.contract_by_id(ahour.contract_id)

          for (const onregel of contract.onregelmatig) {
            let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
            if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
              declaraties.push({ "label": declaratie_cat.name, "value": ahour[declaratie_cat.name] });
            }
          }
          thishour.declaraties = declaraties
          returnhours.push(thishour)
        }
      }
      if (returnhours.length > 0) {
        return returnhours;
      }
      return false
    },
    hasunsigned(index) {
      var ret = false
      for (var adate of this.contractinfo[0].dates[index]) {
        for (var ahour of this.hours) {
          if(ahour.user_id == this.contract_by_id(this.contract_id).user_id && ahour.workdate == adate)
              console.log("Unsigned " , ahour,index)
          // if(ahour.workdate!="0")  console.log(">hasunsigned ",ahour ,this.getWeekTotalInvoice(this.contract_id, this.dayjsweekno, this.dayjsyearno, false))
          if (adate!=0 && ahour.contract_id == this.contract_id && ahour.workdate == adate && ahour.user_id == this.contract_by_id(this.contract_id).user_id) {
            if ((ahour.hours==0 && this.getWeekTotalInvoice(this.contract_id, this.dayjsweekno, this.dayjsyearno, false)!=0) || (ahour.agree_company != 1 && ahour.hours>0) && (ahour.hours > 0 || (ahour.hours==0 && this.getWeekTotalInvoice(this.contract_id, this.dayjsweekno, this.dayjsyearno, false)!=0))) {
              ret = true
            }
            if(ret && ahour.agree_company == 1)
              ret = false;
          }
        }
      }
      return ret
    },
    weektotal() {
//      return this.weektotalhtml(this.contract_id,this.week_year[1]<10?"0"+this.week_year[1] + "-" + this.week_year[0]:this.week_year[1] + "-" + this.week_year[0]  )
      var totalnormal = 0
      var declaraties = new Object()
      let index = 0
      if (this.contractinfo[0].dates[index]) {
        for (var adate of this.contractinfo[0].dates[index]) {
          for (var ahour of this.hours) {

            if (ahour.contract_id == this.contract_id && ahour.workdate == adate && ahour.user_id == this.contract_by_id(this.contract_id).user_id) {
              if(ahour.hours) {
                totalnormal += parseFloat(ahour.hours)
                if(parseFloat(ahour.hours)>0){
                  let checkday=new Date(ahour.workdate)
                  if(checkday.getDay()==0||checkday.getDay()==6){
                    this.show_weekend="1"
                  }
                }
              }

              let contract = this.contract_by_id(ahour.contract_id)

              for (const onregel of contract.onregelmatig) {
                let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
                if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                  if (declaratie_cat.name in declaraties) {
                    if(ahour[declaratie_cat.name] && !isNaN(ahour[declaratie_cat.name])){
                      if(ahour[declaratie_cat.name]){
                        declaraties[declaratie_cat.name] += parseFloat(ahour[declaratie_cat.name])
                      }
                    }
                  } else {
                    if(ahour[declaratie_cat.name] && !isNaN(ahour[declaratie_cat.name])){
                      if(ahour[declaratie_cat.name]){
                        declaraties[declaratie_cat.name] = parseFloat(ahour[declaratie_cat.name])
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      var retstring = "<h2>" + this.$i18n.t("labels.week") + " " + this.$i18n.t("labels.total") + "</h2>" 
      retstring += this.getWeekTotalInvoice(this.contract_id, this.dayjsweekno, this.dayjsyearno, false).toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })+"<br/>"
      retstring += this.$i18n.t("titles.project_hours") +"<strong> " + totalnormal + "</strong><br/>";
      for (var k in declaraties) {
        retstring += k + "<strong> " + declaraties[k].toFixed(2) + "</strong><br/>"
      }
      return retstring
      
    },
    showWeek(item) {
      return "Week <strong> " + (this.dayjsweekno + parseInt(item)) + "</strong>"
    },
    issigned(item) {
      for (var ahour of this.hours) {
        if (ahour.contract_id == this.contract_id && ahour.workdate == item && ahour.user_id == this.contract_by_id(this.contract_id).user_id) {
          return ahour.agree_company == 1
        }
      }
      return false
    },
    sign_week(item) {
      this.hoursToSign = item
      this.checkClose = true
      this.$bvModal.show('BSSignHoursID')
    },
    fake_sign(){
      return this.hoursoverviewHTML(this.contract_id,this.dayjsweekno<10?"0"+this.dayjsweekno + "-" + this.dayjsyearno:this.dayjsweekno + "-" + this.dayjsyearno )

    },
    async hideAbsent(){
      console.log("hide")
      let abs= this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.dayjsweekno && i.year == this.dayjsyearno)
      this.absentstate= abs.length>0 && abs[0].reason!=""?0:1
    },
    async hideSignHoursModal(){
      if(this.checkClose){
        this.checkClose = false
      } else {
        return
      }
      if (this.confirm_modal.id == 1) {
        this.generating=true
        for (const hourdates of this.hoursToSign) {
          if (this.hasdata(hourdates)) {
            for (var ahour of this.hours) {
              if (ahour.contract_id == this.contract_id && ahour.workdate == hourdates && ahour.user_id == this.contract_by_id(this.contract_id).user_id) {
                if (ahour.agree_company != 1) {
                  this.agree_hours(ahour.id);
                }
              }
            }
          }
        }
        // add entry in facturen
        var newFact = { id: 0, week: this.dayjsweekno, contract_id: this.contract_id, user_id: this.contract_by_id(this.contract_id).user_id }
        await this.add_factuur(newFact)        
        // sent message to zzper
        // let email = this.user_by_id(this.userplanning(this.user_id)[0].sign_id).email
        // let name = this.username_by_id(this.userplanning(this.user_id)[0].sign_id)
        let email = this.user_by_id(this.user_id).email
        let name = this.username_by_id(this.user_id)
        let message = {}
        message.email = email
        message.name = name
        message.user_id = this.user_id
        let realplanning = this.planning_by_id(this.contract_by_id(this.contract_id).planning_id)

        message.message = this.settings.hourscheckedmessage.replaceAll(/\[zzper\]/gi, this.username_by_id(this.user_id))
        message.message = message.message.replaceAll(/\[signer\]/gi, this.user_by_id(realplanning.sign_id).firstname)
        message.message = message.message.replaceAll(/\[week\]/gi, this.dayjsweekno)
        message.message = message.message.replaceAll(/\[project_name\]/gi, realplanning.project_name)

        message.subject = this.settings.hourscheckedsubject.replaceAll(/\[signer\]/gi, this.user_by_id(realplanning.sign_id).firstname)
        message.subject = message.subject.replaceAll(/\[zzper\]/gi, this.username_by_id(this.user_id))
        message.subject = message.subject.replaceAll(/\[week\]/gi, this.dayjsweekno)
        message.subject = message.subject.replaceAll(/\[project_name\]/gi, realplanning.project_name)
        message.contract_id = this.contract_id
        message.week = this.dayjsweekno
        message.year= this.dayjsyearno

        message.maindocuments = []
        // send email
        try {
          axios.post('/api/contact_user', message).then((response) => {
            console.log('succes', response)
          })
        } catch (err) {
          console.log(err)
        }
        // notify
        if(this.settings.PMHistory) {
          this.add_pm( { id: 0, to:  this.user_id, from: realplanning.sign_id, message:message })
        }
        try {
          axios.post('/api/notify', message).then((response) => {
            console.log('succes', response)
          })
        } catch (err) {
          console.log(err)
        }     
        
      this.hoursToSign=[]
        // get invoices 
        await this.get_facturen()
        let invoices = this.facturen.filter(invoice => invoice.contract_id==newFact.contract_id && invoice.week == newFact.week)

        for(const invoice of invoices) {
        // SendInvoiceOnChecked generate and send invoice to zpp'er
        //construct data for auto send invoice
        let sendinvoice = {}
          sendinvoice.id = invoice.id
          sendinvoice.user_id = this.contract_by_id(invoice.contract_id).user_id
          sendinvoice.contract_id = invoice.contract_id
          sendinvoice.week = invoice.week
          sendinvoice.year = this.yearnumber
          sendinvoice.hourcode = invoice.hourcode?parseInt(invoice.hourcode):null
          sendinvoice.dates = this.contractinfo[0].dates[0]
          sendinvoice.inkoopfactuur = this.inkoopfactuur//this.userplanning(sendinvoice.user_id)[0].inkoopfactuur
          sendinvoice.invoiceemail= this.company_by_id(this.project_by_id(this.contract_by_id(invoice.contract_id).project_id).company_id).invoice_contants[0] ? this.user_by_id(this.company_by_id(this.project_by_id(this.contract_by_id(invoice.contract_id).project_id).company_id).invoice_contants[0]).id : null
          // generate inkoop + verkoop factuur
          await axios.post('/api/generate_inkoop', sendinvoice)
          await axios.post('/api/generate_verkoop', sendinvoice)
          if(this.settings.SendInvoiceOnChecked){
            await this.sendPurchaseInvoiceEmail(sendinvoice)
          }
          if(this.settings.SendInvoiceSaleOnChecked){
            await this.sendSaleInvoiceEmail(sendinvoice)
          }

        } 
        this.generating=false
      }     
    },  
    isclosed(item) {
      for (var ahour of this.hours) {
        if (ahour.contract_id == this.contract_id && ahour.workdate == item && ahour.user_id == this.user_id) {
          return ahour.close_hours == 1
        }
      }
      return false
    },
    week_is_closed() {
      var dates = this.contractinfo[0].dates
      var isclosed = false
      dates[0].forEach(hourdates => {
        this.hours.forEach(ahour => {
          if (ahour.contract_id == this.contract_id && ahour.workdate == hourdates && hourdates != '0' && ahour.user_id == this.user_id) {
            if (!isclosed && ahour.close_hours == 1) {
              isclosed = true
            }
          }

        });

      });
      return isclosed
    },
    async close_week() {
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: this.settings.closeweekzzpr,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': async () => {

          if (this.confirm_modal.id == 1) {
            for (const hourdates of this.contractinfo[0].dates[0]) {
              var dayclose = false
              for (var ahour of this.hours) {
                if (ahour.contract_id == this.contract_id && ahour.workdate == hourdates && ahour.user_id == this.user_id) {
                  if (ahour.close_hours != 1) {
                    this.close_hours(ahour.id);
                    dayclose = true
                  }
                }
              }
              if (!dayclose) {
                let newhours = {
                  id: 0,
                  user_id: this.user_id,
                  contract_id: this.contract_id,
                  workdate: hourdates,
                  hours: 0,
                  added_date: new Date().toUTCString(),
                  close_hours: 1
                }
                this.add_hours(newhours)

              }
            }
           // sent message to signer
           let realplanning = this.planning_by_id(this.contract_by_id(this.contract_id).planning_id)
           console.log(">>>>>>>>>>>>>>>MAIL", realplanning)
           if (realplanning.mailurencontact == 'yes') {

              let email = this.user_by_id(realplanning.sign_id).email
              let name = this.username_by_id(realplanning.sign_id)
              let message = {}
              message.email = email
              message.name = name
              message.user_id = realplanning.sign_id
              message.message = this.settings.hoursfilledmessage.replaceAll(/\[zzper\]/gi, this.username_by_id(this.user_id))
              message.message = message.message.replaceAll(/\[week\]/gi, this.dayjsweekno)
              message.message = message.message.replaceAll(/\[project_name\]/gi, realplanning.project_name)
              message.message = message.message.replaceAll(/\[signer\]/gi, this.user_by_id(realplanning.sign_id).firstname)
              
              message.message = message.message.replaceAll(/\[hoursoverview\]/gi, this.hoursoverviewHTML(this.contract_id,this.dayjsweekno<10?"0"+this.dayjsweekno + "-" + this.dayjsyearno:this.dayjsweekno + "-" + this.dayjsyearno ))

              message.subject = this.settings.hoursfilledsubject.replaceAll(/\[zzper\]/gi, this.username_by_id(this.user_id))
              message.subject = message.subject.replaceAll(/\[week\]/gi, this.dayjsweekno)
              message.subject = message.subject.replaceAll(/\[project_name\]/gi, realplanning.project_name)
              message.subject = message.subject.replaceAll(/\[signer\]/gi, this.user_by_id(realplanning.sign_id).firstname)
              message.contract_id = this.contract_id
              message.week = this.dayjsweekno
              message.year= this.dayjsyearno
              message.maindocuments = []
              // let emailnotification = parseInt(this.user_by_id(realplanning.sign_id).mail)==1 && this.realplanning.hourregistration=='app'
              let emailnotification = realplanning.mailurencontact == 'yes' && parseInt(this.user_by_id(realplanning.sign_id).mail)==1 && realplanning.hourregistration=='app'
              if(realplanning.copy_id){
                  message.cc = [this.user_by_id(realplanning.copy_id).email]
                }
                if(message.message.indexOf('rating')!=-1){
                  let obj = {}
                  obj.user_id = this.user_id
                  obj.signer_id = realplanning.sign_id
                  obj.contract_id = this.contract_id
                  await axios.post('/api/get_review_user_hash/', obj).then( async (response) => {
                    console.log('succes',response)
                    let encrypted = response.data
                    message.message = message.message.replaceAll(/\[rating\]/gi, 'Klik hier om het werk te beoordelen <br><br><br><a style="text-decoration: none;background-color: #FC8007; border-color: #FC8007;color:#fff;text-align: center; vertical-align: middle;	font-size: 1rem; line-height: 1.5; padding: 0.75rem 1.5rem; border-radius: 2rem;cursor: pointer;height:24px;width:240px;" href="'+encrypted+'">beoordelen</a> ')
                  })
                }
                if(message.message.indexOf('confirmurl')!=-1){
                  let obj = {}
                  obj.contract_id = this.contract_id
                  obj.week = this.dayjsweekno
                  obj.year = this.dayjsyearno
                  await axios.post('/api/get_confirm_hash/', obj)  .then( async (response) => {
                    // console.log('succes',response)
                    let encrypted = response.data
                    message.message = message.message.replaceAll(/\[confirmurl\]/gi, 'Klik hier voor akkoord <br><a style="text-decoration: none;background-color: #FC8007; border-color: #FC8007;color:#fff;text-align: center; vertical-align: middle;	font-size: 1rem; line-height: 1.5; padding: 0.75rem 1.5rem; border-radius: 2rem;cursor: pointer;height:24px;width:240px;" href="'+encrypted+'">Akkoord</a> ')
                    if(emailnotification) {
                      try {
                        axios.post('/api/contact_user', message).then((response) => {
                          console.log('succes', response)
                        })
                      } catch (err) {
                        console.log(err)
                      }
                    }
                  })
                } else {                
                if(emailnotification) {
                  try {
                    axios.post('/api/contact_user', message).then((response) => {
                      console.log('succes', response)
                    })
                  } catch (err) {
                    console.log(err)
                  }
                }
              }
              let inappnotification = parseInt(this.user_by_id(realplanning.sign_id).inapp)==1
              if(inappnotification) {
                if(this.settings.PMHistory) {
                  this.add_pm( { id: 0, from: this.user_id, to: realplanning.sign_id, message:message })
                }
                try {
                  axios.post('/api/notify', message).then((response) => {
                    console.log('succes', response)
                  })
                } catch (err) {
                  console.log(err)
                }     
              }
            }
          }
        }
      })

      this.$emit('close')
    },
    prevweek() {
      // skip  date with 0
      let i=0
      while(this.contractinfo[0].dates[0][i]==0){
        i++
      }        
      let date = new Date(this.contractinfo[0].dates[0][i])
      date.setDate(date.getDate() - 1);
      let sweek = this.getWeekNumber(date)
        // this.weeknumber = parseInt(sweek[1])
        this.weeknumber--
        this.yearnumber = parseInt(sweek[0])    
    },
    nextweek() {
      let last=this.contractinfo[0].dates[0].length-1
      while(this.contractinfo[0].dates[0][last]==0){
        last--
      }

        let date = new Date(this.contractinfo[0].dates[0][last])
        date.setDate(date.getDate() + 1);
        let sweek = this.getWeekNumber(date)
        this.weeknumber++
        this.yearnumber = parseInt(sweek[0])   
      },
    async generated_invoices(){
      this.generating=true
      await this.get_facturen()

      let invoices = this.facturen.filter(invoice => invoice.contract_id==this.contract_id && invoice.week == this.dayjsweekno)

      for(const invoice of invoices) {
        console.log( invoice)
        let sendinvoice = {}
          sendinvoice.id = invoice.id
          sendinvoice.user_id = this.contract_by_id(invoice.contract_id).user_id
          sendinvoice.contract_id = invoice.contract_id
          sendinvoice.week = invoice.week
          sendinvoice.hourcode = invoice.hourcode?parseInt(invoice.hourcode):null
          sendinvoice.dates = this.contractinfo[0].dates[0]
          sendinvoice.inkoopfactuur = this.inkoopfactuur//this.userplanning(sendinvoice.user_id)[0].inkoopfactuur
          sendinvoice.invoiceemail= this.company_by_id(this.project_by_id(this.contract_by_id(invoice.contract_id).project_id).company_id).invoice_contants[0] ? this.user_by_id(this.company_by_id(this.project_by_id(this.contract_by_id(invoice.contract_id).project_id).company_id).invoice_contants[0]).id : null
          sendinvoice.force = true
          sendinvoice.year = this.yearnumber
          // generate inkoop + verkoop factuur
          await axios.post('/api/generate_inkoop', sendinvoice)
          await axios.post('/api/generate_verkoop', sendinvoice)     
       }
       this.generating=false

    },
    generate_factuur(index, hourcode=null) {
      this.generate_inkoop = true
      let data = {}
      data.dates = index
      data.user_id = this.user_id
      data.contract_id = this.contract_id
      data.hourcode = hourcode
      data.week = this.dayjsweekno
      try {
        axios.post('/api/generate_inkoop', data).then(() => {
          var routeUrl 
          if(data.hourcode==null){
            routeUrl = this.server_url + '/media_invoices/factuur-' + this.contract_id + "-" + this.user_id + "-" + this.dayjsweekno + ".pdf"
          } else{
            routeUrl = this.server_url + '/media_invoices/factuur-' + this.contract_id + "-" + this.user_id + "-" + this.dayjsweekno + "-" + data.hourcode + ".pdf"
          }
          window.open(routeUrl, '_blank')
          this.generate_inkoop = false

        })
      } catch (err) {
        console.log(err)
      }
    },
    generate_verkoop_factuur(index, hourcode=null) {
      this.generate_verkoop = true
      let data = {}
      data.dates = index
      data.user_id = this.user_id
      data.contract_id = this.contract_id
      data.week = this.dayjsweekno
      data.year = this.dayjsyearno
      data.hourcode = hourcode
      try {
        axios.post('/api/generate_verkoop', data).then(() => {
          var routeUrl 
          if(data.hourcode==null){
            routeUrl = this.server_url + '/media_invoices/verkoopfactuur-' + this.contract_id + "-" + this.user_id + "-" + this.dayjsweekno + ".pdf"
          } else{
            routeUrl = this.server_url + '/media_invoices/verkoopfactuur-' + this.contract_id + "-" + this.user_id + "-" + this.dayjsweekno + "-" + data.hourcode + ".pdf"
          }

          window.open(routeUrl, '_blank')
          this.generate_verkoop = false

          //    console.log('succes',response)
        })
      } catch (err) {
        console.log(err)
      }
    },
    upload_urenbon(intern) {
      this.uploadintern = intern
      this.$bvModal.show('BSUploadUrenModalID')
    },
    invoice_extra_info() {
      this.$bvModal.show('BSSetinvoiceExtraID')
    },    

    ...mapActions([
      'save_hours',
      'add_hours',
      'agree_hours',
      'close_hours',
      'add_factuur',
      'add_pm',
      'get_facturen'
    ]),

    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    userplanning(user_id) {
      let _planning = []
      for (var i = 0; i < this.planning.length; i++) {
        if (this.planning[i].user_id == user_id) {
          _planning.push(this.planning[i])
        }
      }
      return _planning
    },
    fillhours(day) {
      // find contract for project + user
      this.showday = day
      this.$store.commit('userhourmodal_settings',{contract_id:this.contract_id, day:this.showday, select:true,fromLayer:false})

      this.$bvModal.show('userHoursModalID')
    },
    hashours(date){
        let result={}
        result.available=false;
        let available = this.hours.filter(h => h.workdate== date && h.contract_id==this.contract_id && h.user_id==this.user_id )[0]
        if(available.length==1){
            result.available=true;
              result.id = available.id;
              result.hours = available.hours;
              result.agree_company = available.agree_company;        
        }
        // for(var i=0;i<this.hours.length;i++){
        //   if(this.hours[i].workdate==date){
        //     if(this.hours[i].contract_id==this.contract_id && this.hours[i].user_id==this.user_id){
        //       result.available=true;
        //       result.id = this.hours[i].id;
        //       result.hours = this.hours[i].hours;
        //       result.agree_company = this.hours[i].agree_company;
        //       break;
        //     }
        //   }
        // }
        return result

      },    
    async  addfactuur(){
        // add entry in facturen
        var newFact = { id: 0, week: this.dayjsweekno, contract_id: this.contract_id, user_id: this.contract_by_id(this.contract_id).user_id }
        console.log(newFact)
        await this.add_factuur(newFact)       
    }
  },
  watch: {
    startweek: {
      deep: true,
      handler: function (sweekval) {
        if (this.dayjsweekno < sweekval) {
          //  this.weeknumber=sweekval;
        }
      }
    }
  },
  components: { FilledHours, BSUploadUserModal, UrenBonnen, UserHoursModal,BSInvoiceExtra, BSSignHoursModal,BSAbsentModal }
}
</script>